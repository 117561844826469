<template>
  <div class="contactus">
    <div class="text">
      <!-- <img
        src="../../assets/img/about/gushi/03.png"
        alt=""
      > -->
      <div class="textbox">
        <p class="ename">Contact us</p>
        <p class="nname">联系我们</p>
        <p class="heyj">合作与建议</p>
        <p class="initr1">如果您想与Joocyee合作，或是有其他意见和建议，可以通过邮箱与我们联系</p>
        <p class="heyj2">电子邮箱</p>
        <p class="initr2">joocyee_official@joy-group.com
        </p>

      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.contactus {
  width: 100%;
  height: calc(100vh - 7vh);
  background: url("../../assets/img/about/gushi/3.jpg") no-repeat center center;
  background-size: cover;
  .text {
    text-align: center;
    position: relative;
    z-index: 100;
    width: 23.375rem;
    height: 16.875rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    img {
      width: 23.375rem;
      height: 16.875rem;
    }
    .textbox {
      position: absolute;
      top: 0;
      left: 0.355rem;
    }
    .ename {
      color: #fff;

      font-size: 4.55rem;
      font-family: Arachne;
      line-height: 1.15;
    }
    .nname {
      color: #fff;
      font-size: 1.7rem;
      // text-indent: 0.6rem;
      letter-spacing: 4px;
      margin-bottom: 2rem;
    }
    .heyj {
      color: #fff;
      font-size: 1rem;
      // text-indent: 0.6rem;
      letter-spacing: 4px;
      margin-bottom: 0.5rem;
    }
    .initr1 {
      color: #fff;
      font-size: 0.7rem;
      text-indent: -0.5rem;
      margin-bottom: 1.3rem;
      white-space: nowrap;
      // letter-spacing: 4px;
    }
    .heyj2 {
      color: #fff;
      font-size: 1rem;
      // text-indent: 0.6rem;
      letter-spacing: 4px;
      margin-bottom: 0.3rem;
    }
    .initr2 {
      color: #fff;
      font-size: 0.7rem;
      text-indent: -0.5rem;
      // letter-spacing: 4px;
    }
  }
}
</style>